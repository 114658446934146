import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = 'https://mobile-gateway.dev-api.knostos.com/api/v1';
  private headers = new HttpHeaders({
    'App_id': 'nlSwUjIBL3QpB8++8OWCog==',
  });

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<any> {
    return this.http.get(`${this.baseUrl}/user/notifications`, { headers: this.headers, withCredentials: true });
  }

  getBlockerCards(role: string): Observable<any> {
    const params = { role };
    return this.http.get(`${this.baseUrl}/blockers`, { headers: this.headers, params, withCredentials: true });
  }

  validateSession(): Observable<any> {
    return this.http.get(`${this.baseUrl}/check_session`, { headers: this.headers, withCredentials: true })
  }
}
