import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../Services/auth/auth.service';
import { ApiService } from '../Services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private apiService: ApiService) { }

  canActivate(): boolean {
    let userHasSession = this.apiService.validateSession();

    const currentRole = this.authService.getCurrentRole();
    if (currentRole && userHasSession) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
