import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

interface Role {
  name: string;
  permissions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private rolesSubject = new BehaviorSubject<Role[]>([]);
  private currentRoleSubject = new BehaviorSubject<string | null>(null); // Current role

  constructor(private apiService: ApiService) {
    const savedRoles = localStorage.getItem('userRoles');
    const savedRole = localStorage.getItem('currentRole');

    if (savedRoles) {
      this.rolesSubject.next(JSON.parse(savedRoles));
    }

    if (savedRole) {
      this.currentRoleSubject.next(savedRole);
    }
  }

  setRoles(roles: Role[]): void {
    this.rolesSubject.next(roles);
    localStorage.setItem('userRoles', JSON.stringify(roles));
  }

  getRoles(): Role[] {
    return this.rolesSubject.value;
  }

  setCurrentRole(role: string): void {
    this.currentRoleSubject.next(role);
    localStorage.setItem('currentRole', role);
  }

  isLoggedin(): Observable<any> {
    return this.apiService.validateSession();
  }

  getCurrentRole(): string | null {
    if (this.isLoggedin()) {
      return this.currentRoleSubject.value;
    } else {
      return '';
    }
  }

  logout(): void {
    this.rolesSubject.next([]);
    this.currentRoleSubject.next(null);
    localStorage.removeItem('userRoles');
    localStorage.removeItem('currentRole');
  }
}
