import { Routes } from '@angular/router';
import { AuthGuard } from './Guards/auth-guard.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home-page/home.page').then((m) => m.HomePage),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'patients',
    loadComponent: () => import('./pages/patients-page/patients.page').then(m => m.PatientsPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadComponent: () => import('./pages/orders-page/orders.page').then(m => m.OrdersPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadComponent: () => import('./pages/documents-page/documents.page').then(m => m.DocumentsPagePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'role-switcher',
    loadComponent: () => import('./pages/role-switcher/role-switcher.page').then(m => m.RoleSwitcherPage),
    canActivate: [AuthGuard],
  },
];
