import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type UserState = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  phone: string;
  cellPhone: string;
  knostosId: number;
  facilities: [];
  twixorEnabled: boolean;
  tenantId: string;
  customerId: string;

}

@Injectable({
  providedIn: 'root', // Makes it available app-wide
})
export class StateService {
  private notificationsCountSubject = new BehaviorSubject<number>(0);
  notificationsCount$ = this.notificationsCountSubject.asObservable();

  private userInfoSubject = new BehaviorSubject<UserState | null>(null);

  userInfo$ = this.userInfoSubject.asObservable();
  private readonly userKey = 'userInfo';

  // Update the notifications count
  setNotificationsCount(count: number): void {
    this.notificationsCountSubject.next(count);
  }

  // Get the current notifications count
  getNotificationsCount(): number {
    return this.notificationsCountSubject.getValue();
  }

  // Save user info and persist it in localStorage
  setUserInfo(
    userInfo: UserState | null
  ): void {
    console.log(userInfo?.email);
    this.userInfoSubject.next(userInfo);

    if (userInfo) {
      console.log(userInfo);
      localStorage.setItem(this.userKey, JSON.stringify(userInfo));
    } else {
      localStorage.removeItem(this.userKey);
    }
  }

  // Retrieve user info from BehaviorSubject
  getUserInfo(): UserState | null {
    const cached = this.userInfoSubject.getValue();
    const stored = this. getStoredUserInfo();
    if (cached) {
      return cached;
    } else if (stored) {
      this.userInfoSubject.next(stored);
      return stored;
    }
    return null;
  }

  // Retrieve user info from localStorage
  private getStoredUserInfo(): UserState | null {
    const storedUser = localStorage.getItem(this.userKey);
    return storedUser ? JSON.parse(storedUser) : null;
  }
}
