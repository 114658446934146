import { Routes } from '@angular/router';
import { AuthGuard } from './Guards/auth-guard.guard';

const storedUser = localStorage.getItem('userInfo');
let userInfo: any = {};
if (storedUser) {
  userInfo = JSON.parse(storedUser);
}
export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home-page/home.page').then((m) => m.HomePage),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'patients',
    loadComponent: () =>
      import('./pages/patients-page/patients.page').then((m) => m.PatientsPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadComponent: () =>
      import('./pages/orders-page/orders.page').then((m) => m.OrdersPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadComponent: () =>
      import('./pages/documents-page/documents.page').then(
        (m) => m.DocumentsPagePage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'role-switcher',
    loadComponent: () =>
      import('./pages/role-switcher/role-switcher.page').then(
        (m) => m.RoleSwitcherPage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'submissions',
    loadComponent: () =>
      import('./pages/submissions/submissions.page').then(
        (m) => m.SubmissionsPage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'billing',
    loadComponent: () =>
      import('./pages/billing/billing.page').then((m) => m.BillingPage),
    canActivate: [AuthGuard],
  },
  ...(userInfo.twixorEnabled == true
    ? [
        {
          path: 'chat',
          loadChildren: () =>
            import('chat-library').then((m) => m.ChatPageModule),
          data: {
            //dev
            url: 'https://engagedev.knostos.com/',
            //production
            //url:'https://engage.knostos.com/twixor/',
            customerId: userInfo.cellPhone ?? userInfo.phone,
            eId: '103',
            countryCode: '1',
            //dev
            departmentId: '5e609d4545b3877381866837',
            //production
            //departmentId:'',
            customerDetails: {
              Custumerknostosid: userInfo.knostosId,
              CustumerProfilePicture: userInfo.avatar,
              Custumername: userInfo.firstName,
              Custumerlastname: userInfo.lastName,
              Custumercellnumber: userInfo.cellPhone ?? userInfo.phone,
              Custumeremail: userInfo.email,
              Custumerfacility: userInfo.facilities,
            },
          },
          canActivate: [AuthGuard],
        },
      ]
    : []),
];
